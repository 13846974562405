html {
  @apply h-full;
  font-size: 14px;
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/fonts/OpenSans-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Merriweather';
  src: url('/fonts/Merriweather-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

body {
  @apply bg-background text-text;
  @apply h-full;
}

h1 {
  @apply text-2xl;
  font-weight: 500;
}

h2 {
  font-size: 14px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-stone-600;
  border-radius: 4px;
  padding-right: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-stone-500;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
